#skills {
	background-color: #f1f1f1;
}

.skills-row {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	margin-top: 20px;
	justify-content: center;
}

.skill-item {
	flex: 1;
	flex-basis: 120px;
	margin: 10px 10px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	border-radius: 50px;
    padding: 10px;
    width: 15%;
}

.skill-item:hover {
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.9), 0 6px 20px 0 rgba(0, 0, 0, 0.9);
}
