html {
	scroll-behavior: smooth;
}

.App {
	width: 100%;
	margin: 0;
}

.type-title {
	font-size: 50px;
	text-align: center;
    padding: 20px;
}

h1 {
	font-family: 'Courier New', Courier, monospace;
	font-size: 50px;
	text-align: center;
	padding: 20px;
}

a {
	text-decoration: none;
	color: black;
	font-family: 'Courier New', Courier, monospace;
}
