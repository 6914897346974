.section {
	/* height: 100vh; */
	background-color: white;
}

.about-me-paragraph {
	font-family: 'Courier New', Courier, monospace;
	text-align: center;
    margin-left: 10%;
    margin-right: 10%;
    padding: 10px;

}
